import React, { useEffect, useRef, useState } from "react"
import Layout from "../../components/shared-layout";
import { Alert, Autocomplete, Avatar, Box, InputBase, ListItem, ListItemText, FormControlLabel, CircularProgress, InputAdornment, Radio, RadioGroup, TextField, Typography, Checkbox, Grid, Accordion, AccordionSummary, AccordionDetails, Skeleton, IconButton, Paper, FormControl, FormLabel } from "@mui/material";
import moment from "moment";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import SnowMoutain from "../images/snow-mountain.jpg";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, PADDING_HORIZONTAL_BOOKING, THEME_YELLOW } from "../../constants/style";
import VmButton from "../../components/shared-button";
import useStores from "../../hooks/use-stores"
import { GetLanguage, IsAuthed } from "../../utilities/general";
import * as queryString from "query-string";
import { navigate } from "gatsby";
import { IsEmptyStr } from "../../utilities/field-validation";
import { SearchCategory } from "../../constants/options";
import { isWeb } from "../../utilities/platform";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ExpandMore } from "@mui/icons-material";
import TravelPlanningStepper from "../../components/travel-planning-stepper";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VmCalendar from "../../components/calendar";
import SlideDown from "react-slidedown";
import { AddCartItem, AddItemToCartAsGuest, AUDDateFormat, GetCartItemNumAsGuest } from "../../utilities/cart-item";
import DoneIcon from '@mui/icons-material/Done';
import VmModal from "../../components/shared-modal";
import { AddTPBookingToMemory, IsProductExistedInTPBooking } from "../../utilities/travel-planning";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import BackspaceIcon from '@mui/icons-material/Backspace';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import VmProductDetail from "../../components/shared-product-detail";

const TravelPlanningBookingListPage = observer(({ location }: { location: any }) => {
  const { productStore, rootStore, cartStore } = useStores();
  const { t }: any = useTranslation();
  const { day, places } = queryString.parse(location.search);
  const [filterList, setFilterList] = useState<any[]>([]);
  const [filteredProductList, setFilteredProductList] = useState<any[]>([]);
  // const [activeFilter, setActiveFilter] = useState<any[]>([]);
  const [activeFilter, setActiveFilter] = useState<any>();
  const [openBookingArea, setOpenBookingArea] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [totalTicketQty, setTotalTicketQty] = useState<number>(0);
  const [showAvaResultArea, setShowAvaResultArea] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [ticketQtyDict, setTicketQtyDict] = useState<any[]>([]);
  const [totalLegitQty, setTotalLegitQty] = useState<number>(0);
  const [showModal, setShowModal] = useState<string>("");
  const [product, setProduct] = useState<any>();
  const [refresh, setRefresh] = useState<any>([]);
  const panelRef = useRef(null);
  const bookingRef = useRef(null);
  const availabilityInfoRef = useRef(null);

  useEffect(() => {
    setProduct(null);
    productStore.groupListByBooking = [];
    if (!IsEmptyStr(places)) {
      let placeCoversion: any = places;
      let placeList: any = placeCoversion.split("--");
      setFilterList(placeList);
      placeList.pop();
      placeList.map((place: string, i: number) => {
        if (i == 0) setActiveFilter(place);
        productStore.getGroupProductByString(place)
          .then(() => {
            if (i == 0) setFilteredProductList(productStore.groupListByBooking.filter((f: any) => f.searchKey === place))
          });
      });
    } else navigate("/travel-planning");
  }, []);

  const onToggleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    let filter = event.target.value as string;
    let tempBookingList = productStore.groupListByBooking.filter((f: any) => f.searchKey === filter);
    setActiveFilter(filter);
    setFilteredProductList(tempBookingList);
    setRefresh([]);
  };

  const onTickFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    let filter = event.target.value as string;
    let tempBookingList: any[] = [];
    let tempFilter = activeFilter;
    if (tempFilter.includes(filter)) {
      let idx = tempFilter.indexOf(filter);
      tempFilter.splice(idx, 1);
    } else tempFilter.push(filter);
    tempBookingList = productStore.groupListByBooking.filter((f: any) => tempFilter.includes(f.searchKey));
    setActiveFilter(tempFilter);
    setFilteredProductList(tempBookingList);
    setRefresh([]);
  }
  const onClickEvent = (date: string) => {
    setErrorMsg("");
    setSelectedDate(date);
    getPriceOptions(date);
  }

  const startBooking = (p: any) => {
    setProduct(p);
    productStore.getProductDateRange(p.tid);
    setTotalLegitQty(0);
    setTicketQtyDict([]);
    setShowAvaResultArea(false);
    setOpenBookingArea(true);
    // @ts-ignore
    if (bookingRef) bookingRef.current.scrollIntoView(({ behavior: 'smooth' }));
  }
  const closeBooking = () => {
    setOpenBookingArea(false);
    setProduct(null);
  }
  const getPriceOptions = (date: string) => {
    setTotalLegitQty(0);
    productStore.getLowestPrice(product.tid, date)
      .then((options: any) => {
        if (options.priceJson) {
          let priceOptions = options.priceJson.map((pInfo: any) => ({
            ...pInfo, quantity: 0,
          }));
          setTicketQtyDict(priceOptions);
        }
      });
  }

  const onChangetTicketQuantity = (action: "+" | "-", code: string, quantitySize: number) => {
    setErrorMsg("");
    let quantity = ticketQtyDict.find(t => t.code === code).quantity;
    ticketQtyDict.find(t => t.code === code)
      .quantity = action === "+" ? quantity + 1 : quantity - 1;
    // check total quantity size from user input
    if (quantitySize != 0) {
      if (action === "+") setTotalLegitQty(+totalLegitQty + +quantitySize);
      else setTotalLegitQty(+totalLegitQty - +quantitySize);
    }
    setTicketQtyDict(ticketQtyDict);
    setRefresh([]);
  }

  const onCheckAvailability = () => {
    const qtyMax: number = productStore.priceOptions.qtyMax;
    const qtyMin: number = productStore.priceOptions.qtyMin;
    if (productStore.priceOptions.qtyMin != 0 && totalLegitQty < qtyMin) {
      rootStore.notify(t('INVALID_TICKET_QUANTITY_SELECTED'), "warning");
      setErrorMsg(t('MIN_QTY') + `: ${qtyMin}`);
      return;
    } else if (productStore.priceOptions.qtyMax != 0 && totalLegitQty > qtyMax) {
      rootStore.notify(t('INVALID_TICKET_QUANTITY_SELECTED'), "warning");
      setErrorMsg(t('MAX_QTY') + `: ${qtyMax}`);
      return;
    }
    // @ts-ignore
    if (availabilityInfoRef) availabilityInfoRef.current.scrollIntoView(({ behavior: 'smooth' }));
    productStore.checkAvailability({
      productId: product.tid,
      departDate: selectedDate,
      language: GetLanguage(),
      quantities: ticketQtyDict.filter((t: any) => t.quantity > 0).map((ticket: any) => ({
        priceType: ticket.code,
        qty: ticket.quantity,
      }))
    }).then(() => setShowAvaResultArea(true))
      .catch(() => rootStore.notify(t('OPERATION_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN'), 'error'));
  }

  const addItemToCart = (timeslot?: any) => {
    productStore.loading = true;
    let cartItem: AddCartItem = {
      productId: product.tid,
      departDate: selectedDate,
      language: GetLanguage(),
      qty: productStore.avaInfo.quantities.filter((q: any) => q.qty != 0).map((ticket: any) => ({
        priceType: ticket.priceType,
        qty: ticket.qty,
      }))
    }
    console.log();
    if (productStore.avaInfo.isApi && timeslot) {
      cartItem.timeId = timeslot.id;
      cartItem.time = timeslot.name;
    }
    cartStore.addCartItem(cartItem).then((item: any) => {
      // productStore.loading = false;
      setShowAvaResultArea(false);
      rootStore.notify(t('PRODUCT_HAS_ADDED_TO_YOUR_CART'), 'success');
      if (IsAuthed()) cartStore.getCartItemNumber();
      else {
        AddItemToCartAsGuest(item);
        GetCartItemNumAsGuest();
        // @ts-ignore
        AddTPBookingToMemory(day, product.tid, product.name ? product.name : product.searchTerms, activeFilter);
      }
      setShowModal(productStore.avaInfo.title);
    }).catch(() => rootStore.notify(t('OPERATION_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN'), 'error'))
      .finally(() => productStore.loading = false);
  }

  const onCheckProductBooked = (sup: any) => {
    if (sup.groupProducts) {
      for (var p of sup.groupProducts) {
        if (IsProductExistedInTPBooking(p.tid)) return true;
      }
    } else {
      if (IsProductExistedInTPBooking(sup.tid)) return true;
    }
    return false;
  }

  return (
    <Layout pageName={t('TRAVEL_PLANNING')}>
      <Box className={PADDING_HORIZONTAL_BOOKING} ref={panelRef}>
        <TravelPlanningStepper activeStep={2} />
        {/* @ts-ignore */}
        <p className="font-bold text-lg 2xl:text-xl mb-6">{t('STEP_3_SELECT_A_PRODUCT_FROM_THE_SCHEDULED_PLACES_AND_ADD_TO_CART')} <span className="text-sky-500">({t('DAY_A')}{+day + 1}{t('DAY_B')})</span>:</p>
        {productStore.groupListByBooking.length == 0 || productStore.loading ? <Box className="text-center mt-10">
          <CircularProgress />
          <p className="mt-4 text-xl">{t('LOADING')}...</p>
        </Box> : <>
          <Paper ref={bookingRef} className="shadow-lg border mb-4 py-2 px-4">
            <FormControl>
              <FormLabel>{t('ATTRACTIONS')}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={activeFilter}
                onChange={onToggleFilter}
              >
                {filterList.map((filter: string, i: number) => (
                  <FormControlLabel
                    key={`filter_${i}`}
                    control={<Radio
                      value={filter}
                      sx={{ color: "gray", '&.Mui-checked': { color: THEME_YELLOW } }} />}
                    label={`${filter} (${productStore.groupListByBooking.find((b: any) => b.searchKey === filter) ?
                      productStore.groupListByBooking.find((b: any) => b.searchKey === filter).items.length : 0})`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Paper>
          {/* Uncomment if u need to use checkbox */}
          {/* <Box ref={bookingRef} className="grid grid-cols-5 gap-4 shadow-lg border mb-4 py-2 px-4">
            {filterList.map((filter: string, i: number) => (
              <FormControlLabel
                key={`filter_${i}`}
                control={<Checkbox
                  value={filter}
                  checked={activeFilter.includes(filter) ? true : false}
                  onChange={onTickFilter}
                  sx={{ color: "gray", '&.Mui-checked': { color: THEME_YELLOW } }} />}
                label={`${filter} (${productStore.groupListByBooking.find((b: any) => b.searchKey === filter) ?
                  productStore.groupListByBooking.find((b: any) => b.searchKey === filter).items.length : 0})`}
              />
            ))}
          </Box> */}
          <Box sx={ITEM_PERFECT_INLINED} className="mb-4">
            <Paper sx={{ color: 'white', background: 'red' }}>
              <button className="p-4" onClick={() => window.close()}>
                <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
                  <BackspaceIcon />
                  <p className="ml-2">{t('BACK_TO_PLANNING')}</p>
                </Box>
              </button>
            </Paper>
            <Paper sx={{ color: 'white', background: "#38bdf8", ml: 2 }}>
              <button className="p-4" onClick={() => navigate("/cart")}>
                <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
                  <ShoppingCartCheckoutIcon />
                  <p className="ml-2">{t('PROCEED_TO_CHECKOUT')}</p>
                </Box>
              </button>
            </Paper>
          </Box>
          {/* Left - Product Section */}
          <Box className="grid grid-cols-5 gap-2">
            <Box className={`${openBookingArea ? 'col-span-2 max-h-bookingH overflow-auto' : 'col-span-5'} transition-all`}>
              {filteredProductList.map((supplier: any, i: number) => (
                supplier.items.map((product: any, idx: number) => (
                  <Accordion key={`supplier_${i}`} sx={{ mb: 2, boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      className="shadow-lg">
                      <Box className={`grid grid-cols-5 ${openBookingArea ? 'gap-2' : 'gap-4'} w-full`}>
                        <Box className="px-4">
                          <img src={product.webThumb} className={`h-32 w-full object-cover rounded-lg`} />
                        </Box>
                        <Box className="col-span-4 relative">
                          <Box className="absolute top-1/2 translate-y-neg-1/2 left-0 w-full">
                            <p className={`${openBookingArea ? 'lg:text-lg 2xl:text-xl' : 'text-base 2xl:text-lg'} `}>{product.searchTerms}</p>
                            {onCheckProductBooked(product) && <Box sx={ITEM_PERFECT_INLINED}>
                              <span className="text-green-500"><CheckCircleIcon color="inherit" /></span>
                              <p className="text-green-500 ml-1 pt-1">{t('BOOKED')}</p>
                            </Box>}
                            {product.webGrabline && <p className="font-light lg:text-xs 2xl:text-sm text-gray-500 mt-2">{openBookingArea ? `${product.webGrabline.substring(0, 80)}...` : product.webGrabline}</p>}
                          </Box>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="shadow-md" sx={{ background: 'white', border: '1px solid #efefef', borderTopWidth: 0 }}>
                      <Box className="p-2">
                        {product.groupProducts ? product.groupProducts.map((gp: any, i: number) => (
                          <GroupProducts key={`gp_${i}`} product={gp} onClick={() => startBooking(gp)} />)) : <GroupProducts key={`gp_${i}`} product={product} onClick={() => startBooking(product)} />}
                      </Box>
                    </AccordionDetails>
                  </Accordion>))
              ))}
            </Box>
            {/* End Left Section */}

            {/* Right - Booking Section */}
            {openBookingArea && <Box className="col-span-3">
              <Box className="bg-white border shadow-lg">
                <Box sx={ITEM_PERFECT_INLINED} className="justify-between pl-4">
                  <Box className="font-bold">{t('BOOKING')}:</Box>
                  <IconButton onClick={closeBooking}>
                    <ClearIcon fontSize="large" />
                  </IconButton>
                </Box>
                <p className="px-4 pb-4">{product ? `${product.name ? product.name : product.searchTerms}` : t('NO_PRODUCT_SELECTED')}</p>
              </Box>
              {product && <Box className="grid grid-cols-1 sm:grid-cols-2">
                {/* Ticket options */}
                <Box className="border-gray-200 p-4 bg-white border shadow-md">
                  <p className="lg:text-lg xl:text-xl font-bold mb-4">{t('STEP_1_TRAVEL_DATE')}:</p>
                  {/* <Box className="px-10"> */}
                  {productStore.loadingDateRange ? <Skeleton variant="rectangular" width="100%" height={300} />
                    : <VmCalendar onClickEvent={onClickEvent} />}
                  {/* </Box> */}
                </Box>
                {/* End ticket options */}
                {/* Select date area */}
                <Box className="border-gray-200 p-8 pt-4 bg-white border shadow-md">
                  <p className="lg:text-lg xl:text-xl font-bold mb-2">{t('STEP_2_PRICE')}:</p>
                  <Box className="border-4 bg-gray-200 bg-white">
                    <p className="bg-green-500 text-white font-light px-2 py-1 uppercase">{t('SELECTED_TRAVEL_DATE')}</p>
                    <p className="text-center p-2 sm:text-lg 2xl:text-xl font-bold uppercase">{!IsEmptyStr(selectedDate) ? moment(selectedDate).format(AUDDateFormat) : t('NO_SELECTED')}</p>
                  </Box>
                  {productStore.loadingDateRange || productStore.loadingPriceOptions ? <><Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: 2 }} /><Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: 2 }} /><Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: 2 }} /></>
                    : ticketQtyDict.length == 0 ? <p className="text-center mt-8">{t('NO_TICKET_OPTIONS_AVA')}</p>
                      : <>
                        {ticketQtyDict.map((ticket: any, i: number) => (
                          <Box key={`ticket_option_${i}`} sx={ITEM_PERFECT_INLINED} className="flex justify-between my-2">
                            <p className={`pl-2 ${ticket.quantity > 0 && 'text-sky-400'}`}>{ticket.name} <span className="text-xs text-gray-400 font-light">{ticket.remark}</span></p>
                            <Box sx={ITEM_PERFECT_INLINED}>
                              <IconButton disabled={ticket.quantity == 0} className="mx-2" onClick={() => onChangetTicketQuantity("-", ticket.code, ticket.totalQty)}>
                                <RemoveCircleIcon color="inherit" />
                              </IconButton>
                              <Typography>{ticket.quantity}</Typography>
                              <IconButton className="ml-2" onClick={() => onChangetTicketQuantity("+", ticket.code, ticket.totalQty)}>
                                <AddCircleIcon color="inherit" />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                        <Box ref={availabilityInfoRef} className="flex justify-center my-4 py-4 border-t border-b">
                          <VmButton
                            className="px-14 py-4" onClick={onCheckAvailability}
                            disabled={ticketQtyDict.filter((t: any) => t.quantity > 0).length == 0 || totalLegitQty == 0}>
                            {t('CHECK_AVAILABILITY')}
                          </VmButton>
                        </Box>
                        {errorMsg !== "" && <p className="text-red-500 text-center p-2">{errorMsg}</p>}
                      </>}
                </Box>
                {/* End select date area */}
                {productStore.checkingAva || cartStore.loading ? <Skeleton className="col-span-1 sm:col-span-2" variant="rectangular" width="100%" height={50} sx={{ marginTop: 2 }} />
                  : (productStore.avaInfo && showAvaResultArea) && <Box className="col-span-1 sm:col-span-2 shadow-lg border mt-4">
                    <Box className="grid grid-cols-1 sm:grid-cols-4 gap-4 p-4">
                      <Box className="col-span-3">
                        <p className="font-bold text-xl 2xl:text-2xl">{productStore.avaInfo.title}</p>
                        <p className="font-bold text-xl 2xl:text-2xl my-2">{t('DEPARTURE_DATE')}: {moment(selectedDate).format(AUDDateFormat)}</p>
                        {productStore.avaInfo.quantities.filter((q: any) => q.qty != 0)
                          .map((qty: any, i: number) => (
                            <p key={`qty_ava_${i}`} className="text-base 2xl:text-lg text-gray-400 font-light">{qty.qty} {qty.priceName} x ${qty.subTotal.toFixed(2)}</p>
                          ))}
                      </Box>
                      <Box className={`sm:text-right relative`}>
                        <p className="text-red-500 text-3xl 2xl:text-4xl font-bold">A${productStore.avaInfo.totalPrice.toFixed(2)}</p>
                        {(productStore.avaInfo.availability.length == 0 && productStore.avaInfo.available) && <Box className="w-full"><VmButton disabled={!productStore.avaInfo.available} onClick={() => addItemToCart()} className="sm:absolute right-0 bottom-0 w-full">{productStore.avaInfo.available ? t('ADD_TO_CART') : t('UNAVAILABLE')}</VmButton></Box>}
                      </Box>
                      {(productStore.avaInfo.availability.length == 0 && !productStore.avaInfo.available) && <p className="text-red-500 font-bold mb-4 col-span-3 sm:col-span-4">{t('NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE')}</p>}
                      {productStore.avaInfo.availability.length > 0 && <Box className="col-span-4">
                        {productStore.avaInfo.availability.filter((ava: any) => ava.qty > 0).length == 0 ? <p className="text-red-500 font-bold mb-4">{t('NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE')}</p>
                          : productStore.avaInfo.availability.map((ava: any, i: number) => (
                            <VmButton key={`ava_time_select_${i}`} disabled={ava.qty < 1 || totalLegitQty > ava.qty} bg={ava.qty > 0 || totalLegitQty > ava.qty ? 'bg-sky-400' : 'bg-gray-400'} borderColor={ava.qty > 0 || totalLegitQty > ava.qty ? 'border-sky-400' : 'border-gray-400'} onClick={() => addItemToCart(ava)} className="mr-2 mb-2">
                              {ava.qty > 0 && totalLegitQty <= ava.qty ? t('ADD_TO_CART')
                                : ava.qty <= 0 ? t('UNAVAILABLE')
                                  : `${t('ONLY')} ${ava.qty} ${t('LEFT')}`} ({moment(ava.name.split(' ').length > 1 ? ava.name.split(' ')[1] : ava.name, 'HH:mm').format('hh:mm A')})
                            </VmButton>
                          ))}
                      </Box>}
                    </Box>
                  </Box>}
              </Box>}
            </Box>}
            {/* End Right Section */}
          </Box>
        </>}
      </Box>

      <VmModal width="60%" openModal={showModal !== ""} onClose={() => { }}>
        {!IsEmptyStr(showModal) && <Box className="px-4 py-8">
          <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
            <span className="text-sky-500"><DoneIcon fontSize="large" color="inherit" /></span>
            <p className="text-center sm:text-left text-base sm:text-2xl 2xl:text-3xl font-bold uppercase">{t('YOUR_ITEM_HAS_BEEN_ADDED')}</p>
          </Box>
          <p className="text-sm sm:text-lg 2xl:text-xl font-light text-sky-500 text-center mt-4">{showModal}</p>

          <Box sx={ITEM_PERFECT_INLINED} className="text-center sm:text-left block sm:flex justify-center mt-8">
            <VmButton className="mr-0 mb-2 sm:mb-0 sm:mr-4" borderColor="border-sky-400" color="text-sky-400" bg="bg-transparent" onClick={() => {
              setShowModal("")
              // productStore.loading = false;
              // if (isWeb) window.location.reload();
            }}>{t('CONTINUE_SHOPPING')}</VmButton>
            {cartStore.cartItemNum > 1 ? <VmButton onClick={() => navigate('/cart')}>{t('PROCEED_TO_CART')}</VmButton>
              : <VmButton onClick={() => navigate('/cart/check-out')}>{t('PROCEED_TO_CHECKOUT')}</VmButton>}
          </Box>
        </Box>}
      </VmModal>

    </Layout>
  )
});

const GroupProducts = observer(({ product, onClick }: any) => {
  const { t }: any = useTranslation();
  const [detailModal, setDetailModal] = useState<number>(0);

  return (
    <>
      <Box className="grid grid-cols-7 mt-2 font-light">
        <Box className="col-span-5">
          <div className={`w-full h-full border ${IsProductExistedInTPBooking(product.tid) && "border-green-500"} p-1 2xl:p-2`}>
            <Box sx={ITEM_PERFECT_INLINED}>
              <p className={`${IsProductExistedInTPBooking(product.tid) ? 'text-black' : 'text-gray-500'} text-sm 2xl:text-base`}>{product.name ? product.name : product.searchTerms}</p>
              {IsProductExistedInTPBooking(product.tid) && <Box sx={ITEM_PERFECT_INLINED}>
                <span className="text-green-500 ml-4"><CheckCircleIcon color="inherit" /></span>
                <p className="text-green-500 ml-1 pt-1">{t('BOOKED')}</p>
              </Box>}
            </Box>
          </div>
        </Box>
        <button className={`border border-l-0 hover:border-l hover:border-sky-400 ${IsProductExistedInTPBooking(product.tid) && "border-green-500"}`} onClick={onClick}>
          <ShoppingCartIcon fontSize="small" />
          <p className="text-xs 2xl:text-sm">{t('BOOK')}</p>
        </button>
        <button
          onClick={() => setDetailModal(product.tid)}
          className={`border border-l-0 hover:border-l hover:border-sky-400 ${IsProductExistedInTPBooking(product.tid) && "border-green-500"}`}>
          {/* <a href={`/product-detail?productId=${product.tid}`} target="_blank"> */}
          <RemoveRedEyeIcon fontSize="small" />
          <p className="text-xs 2xl:text-sm">{t('VIEW')}</p>
          {/* </a> */}
        </button>
      </Box>

      <VmModal width="70%" openModal={detailModal > 0} onClose={() => setDetailModal(0)}>
        <Box className="bg-white overflow-auto py-8" sx={{ maxHeight: '80vh' }}>
          {detailModal > 0 && <VmProductDetail productId={detailModal} />}
        </Box>
      </VmModal>
    </>
  )
});


export default TravelPlanningBookingListPage;
