import { Backdrop, Box, Fade, ListItem, ListItemText, Modal, Paper, Skeleton, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { observer } from "mobx-react-lite"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { SearchResultType } from "../constants/options";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL } from "../constants/style";
import useStores from "../hooks/use-stores"
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Autoplay, Navigation, Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { GetLanguage } from "../utilities/general";
const contentStyle = "font-light text-xs 2xl:text-sm";
const subContentStyle = "font-light text-sm 2xl:text-base";

const VmProductDetail = observer(({ productId }: { productId: number }) => {
  const { t }: any = useTranslation();
  const { productStore } = useStores();
  const [activeTab, setActiveTab] = useState<"KeyFacts" | "Overview" | "Policy" | "Reviews">("KeyFacts");
  const tabStyle = `text-sm sm:text-base hover:bg-sky-400 hover:text-white p-4 transition-all border-r border-white`;
  const subHeadingStyle = "border-l-4 border-sky-400 pl-4 mt-8 mb-4";

  useEffect(() => {
    if (productId) {
      productStore.getProductDetailById(productId, GetLanguage(), true);
    }
  }, []);

  return (
    <>
      {productStore.loadingModal ? <>
        <Skeleton width="100%" height={100} />
        <Skeleton width="100%" height={200} />
      </> : productStore.productDetail ? <>
      <p className="text-sky-500 font-bold text-3xl text-center mb-4">{productStore.productDetail.title}</p>
        <Box className="shadow-lg border-t flex">
          <Box className={PADDING_HORIZONTAL}>
            <button className={`${tabStyle} ${activeTab === "KeyFacts" && 'bg-sky-400 text-white'}`} onClick={() => setActiveTab("KeyFacts")}>{t('KEY_FACTS')}</button>
            <button className={`${tabStyle} ${activeTab === "Overview" && 'bg-sky-400 text-white'}`} onClick={() => setActiveTab("Overview")}>{t('OVERVIEW')}</button>
            <button className={`${tabStyle} ${activeTab === "Policy" && 'bg-sky-400 text-white'}`} onClick={() => setActiveTab("Policy")}>{t('POLICY')}</button>
            <button className={`${tabStyle} ${activeTab === "Reviews" && 'bg-sky-400 text-white'}`} onClick={() => setActiveTab("Reviews")}>{t('YOTPO_REVIEWS')}</button>
          </Box>
        </Box>
        <Box className="bg-white">
          <Box className={PADDING_HORIZONTAL}>
            <Box className="grid grid-cols-1 sm:grid-cols-2 mt-4 overflow-hidden">
              {activeTab === "KeyFacts" ? <>
                <Box>
                  <Box className={subHeadingStyle}>{t('INCLUDED')}</Box>
                  {productStore.productDetail.included.split('\r\n').map((include: string, i: number) => (
                    <ListItem alignItems="flex-start" className="text-green-400" key={`detail_sect_included_list_${i}`}>
                      {(include.includes('.') && !include.includes('•')) && <DoneRoundedIcon color="inherit" fontSize="small" className="mt-1" />}
                      <ListItemText>
                        <p className={`${contentStyle} ml-1 text-black`}>{include}</p>
                      </ListItemText>
                    </ListItem>
                  ))}
                  <Box className={subHeadingStyle}>{t('EXCLUDED')}</Box>
                  {productStore.productDetail.excluded.split('\r\n').map((exclude: string, i: number) => (
                    <ListItem alignItems="flex-start" className="text-red-400" key={`detail_sect_excluded_list_${i}`}>
                      <CloseRoundedIcon color="inherit" fontSize="small" className="mt-1" />
                      <ListItemText>
                        <p className={`${contentStyle} ml-1 text-black`}>{exclude}</p>
                      </ListItemText>
                    </ListItem>
                  ))}
                  <Box className={subHeadingStyle}>{t('DESTINATION')}</Box>
                  {productStore.productDetail.destination.map((dest: any, i: number) => (
                    <li className={`${subContentStyle}`} key={`dest_${i}`}>{dest.name}</li>
                  ))}
                  <Box className={subHeadingStyle}>{t('DEPARTURE_POINT')}</Box>
                  {productStore.productDetail.departurePoint.split('\r\n').map((info: string, i: number) => (
                    <p className={`${subContentStyle} mb-2`} key={`dp_${i}`}>{info}</p>
                  ))}
                </Box>
                <Box>
                  <Box className={subHeadingStyle}>{t('HOW_TO_GET_THERE')}</Box>
                  {productStore.productDetail.howToGetThere.split('\r\n').map((htgt: string, i: number) => (
                    <p className={`${subContentStyle} mb-2`} key={`htgt_list_${i}`}>{htgt}</p>
                  ))}
                  {productStore.productDetail.operatingHours && <>
                    <Box className={subHeadingStyle}>{t('OPERATING_HOURS')}</Box>
                    <TableBody component={Paper}>
                      {productStore.productDetail.operatingHours.map((hourInfo: any, i: number) => (
                        <TableRow key={`operating_hour_${i}`}>
                          <TableCell>{hourInfo.season}</TableCell>
                          <TableCell>{hourInfo.time}</TableCell>
                          <TableCell>{hourInfo.last}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>}
                  {productStore.productDetail.checkInRequirements !== "" && <Box className={subHeadingStyle}>{t('CHECK_IN_REQUIREMENT')}</Box>}
                  <p className={`${subContentStyle} mb-2`}>{productStore.productDetail.checkInRequirements}</p>
                  {productStore.productDetail.whatToBringAndWear !== "" && <Box className={subHeadingStyle}>{t('WHAT_TO_BRING_WEAR')}</Box>}
                  <ul className={`${subContentStyle} mb-2`}>{productStore.productDetail.whatToBringAndWear.split('● ')
                    .map((label: string, i: number) => (
                      <li key={`what_to_bring_and_wear_${i}`} className={i == 0 ? "hidden" : ""}>{label}</li>
                    ))}</ul>
                </Box>
                <Box className="sm:col-span-2 border-t mt-4">
                  <Box className={subHeadingStyle}>{t('OTHER_INFO')}</Box>
                  {productStore.productDetail.otherInfo.split('\r\n').map((info: string, i: number) => (
                    <ListItem alignItems="flex-start" key={`detail_sect_other_info_list_${i}`}>
                      <ListItemText>
                        <p className={`${contentStyle} text-black`}>{info}</p>
                      </ListItemText>
                    </ListItem>
                  ))}
                </Box>
              </> : activeTab === "Overview" ? <>
                <Box className="col-span-2">
                  <Box className={subHeadingStyle}>{t('OVERVIEW')}</Box>
                  <p className={`${subContentStyle} mb-2`}>{productStore.productDetail.description}</p>
                  {productStore.productDetail.webImageList.length > 0 &&
                    <Swiper
                      modules={[Autoplay, Navigation, Pagination]}
                      spaceBetween={0}
                      slidesPerView={1}
                      pagination={{ clickable: true }}
                      navigation={true}
                      autoplay={{ delay: 5000, disableOnInteraction: false }}
                    >
                      {productStore.productDetail.webImageList.map((image: any, i: number) => (
                        <SwiperSlide key={`image_gallery_${i}`}>
                          <p className="border-l-4 border-white z-10 absolute pl-4 left-0 bottom-0 m-10 translate-x-neg-45 translate-y-neg-45 text-white lg:text-xs 2xl:text-sm">{image.en}</p>
                          <img className="w-full lg:h-60vh 2xl:h-60vh" style={{ objectFit: 'cover', filter: "brightness(60%)" }} src={image.image} />
                        </SwiperSlide>
                      ))}
                    </Swiper>}
                  <Box className={subHeadingStyle}>{t('ITINERARY')}</Box>
                  <div className={`${subContentStyle} overview-area`} style={{ minHeight: 200 }} dangerouslySetInnerHTML={{ __html: productStore.productDetail.itineraryDescription }} />
                </Box>
              </> : activeTab === "Policy" ? <Box className="col-span-2">
                <Box className={subHeadingStyle}>{t('POLICY')}</Box>
                <ul className={`${subContentStyle} mb-2`}>
                  {productStore.productDetail.freeCancellation.split('● ')
                    .map((label: string, i: number) => (
                      <li key={`free_cancellation_${i}`} className={i == 0 ? "hidden" : ""}>{label}</li>
                    ))}
                </ul>
              </Box> : <></>}
            </Box>
          </Box>
        </Box>
      </> : <p className={`text-3xl font-bold text-center`}>{t('PRODUCT_NOT_RELEASED')}</p>}
    </>
  )
})

export default VmProductDetail;
